import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Paper: Rémi Carayon" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article
            key={node.fields.slug}
            style={{
              flex: "0 0 17.5%",
              margin: rhythm(1 / 2),
            }}
          >
            {" "}
            <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  {title}
                </h3>
              </header>
              <section>
                {" "}
                <Img fluid={node.frontmatter.featured.imgnav.fluid} />
              </section>{" "}
            </Link>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            category
            featured {
              img: childImageSharp {
                fluid(maxWidth: 1200, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
              imgnav: childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
